/* LogoutPage.module.css */
.logoutPage {
  text-align: center;
  padding: 20px;
}

.logoutPage p {
  font-size: 18px;
  margin-bottom: 20px;
}

.logoutPage button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
}

.logoutPage button:first-child {
  background-color: #ccc;
}