body {
  background: #ffffff;
}

.home {
  position: relative;
  flex-grow: 1;
}

.hero {
  position: relative;
  width: 100%;
  height: 85vh;
  background-position: center;
  background-size: cover;
  background-image: url('../images/ImgBanner.jpg');
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Добавьте это свойство */
  justify-content: center; /* Добавьте это свойство */
}

.button:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.button {
  transition: transform 0.2s ease-in-out;
}

.hero::before{
  z-index: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}


.title {
  font-weight: 300;
  font-family: 'Crimson Pro';
  font-style: italic;
  z-index: 1;
  font-size: 119px;
  line-height: 145px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative; /* Добавьте это свойство */
  opacity: 0;
  transform: translateY(10px);  
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.titleVisible {
  /* Стили для видимого текста */
  opacity: 1;
  transform: translateY(0);
}

.product {
  align-self: center;
  position: relative;
  display: flex;
  text-align: center;
  margin-top: 9px;
  justify-content: center;
  align-items: center;
}

.subtitle {
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffbf00;
  font-size: 20px;
}

.head {
  font-weight: 300px;
  font-size: 26px;
  line-height: 50px;
  text-align: center;
  color: var(--light);
  max-width: 1200px;
  margin: 10px 0 24px 0;
}



.image {
  position: relative;
  flex-grow: 1;
  height: 250px;
}

.image img {
  position: absolute;
  bottom: -15%;
  right: 10%;
  max-width: unset;
}

.banner {
  display: flex;
  width: 100%;
  height: 590px;
  color: #ffffff;
}

.left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.left:before,
.left:after {
  content: "";
  display: block;
  position: absolute;
}




.content {
  color: var(--violet);
  font-weight: 300;
  font-size: 70px;
  line-height: 85px;
  text-align: center;
}

.content span {
  line-height: 183px;
  font-size: 150px;
  display: block;
}

.more {
  margin-top: 27px;
}

.right {
  width: 50%;
  position: relative;
}

.discount {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--bg);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
}

.discount span {
  color: var(--violet);
  font-weight: 700;
}

.banner iframe {
  border-radius: 2%;
  margin: 10px;
  width: 100vw;
  background-color: #ffffff;
  }

.banner {
  color: #ffffff;
  width: 100%;
  }

.opisanie{
  width: 570px;
  height: 300px;
  margin-left: 50px;
  margin-top: 50px;

}

.opisanie h2{
  font-size: 50px;
}

.opisanie p{
  font-size: 18px;
  margin-top: 15px;
}

.pochemu {
  font-weight: 300px;
  font-size: 26px;
  line-height: 50px;
  text-align: center;
  color: var(--light);
  max-width: 1200px;
  margin: 10px 0 24px 0;
}

.rev{
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}


.reviewCard {
  width: 100vw; /* Ширина карточки, настройте по своему усмотрению */
  height: 100vh;
  border: 1px solid #ffffff;
  margin-left: auto;
  background-color: #ffffff;
  box-sizing: border-box; /* Расстояние между карточками, настройте по своему усмотрению */
}


@media (min-width: 950px) {
  .head {
    font-weight: 300px;
    font-size: 26px;
    line-height: 50px;
    text-align: center;
    color: var(--light);
    max-width: 1200px;
    margin: 10px 0 24px 0;
  }
}

@media (max-width: 950px) {
  .title {
    font-weight: 300;
    font-size: 90px;
    line-height: 145px;
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
  .head {
    font-weight: 300px;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: var(--light);
    padding: 10px;
    max-width: 767px;
    margin: 10px 0 24px 0;
  }
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 767px) {
  .title {
    font-weight: 300;
    font-size: 70px;
    line-height: 70px;
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
  .head {
    font-weight: 300px;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    color: var(--light);
    padding: 10px;
    max-width: 767px;
    margin: 10px 0 24px 0;
  }
}

@media (max-width: 376px) {
  .title {
    font-weight: 300;
    font-size: 45px;
    line-height: 70px;
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
  .head {
    font-weight: 300px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--light);
    padding: 10px;
    max-width: 767px;
    margin: 10px 0 24px 0;
  }
  .rev{
    width: 376px;
    margin: 0 auto;
  }
  .bannerStyle{
    width: 760px,
  }
  
}



