.wrapper {
  width: 100%;
  padding: 24px;
  flex-grow: 1;
}

.filters {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 24px;
}

.filter {
  background: var(--bg);
  border-radius: 8px;
  padding: 12px 24px;
  width: max-content;
  display: flex;
  align-items: center;
  min-width: 225px;
}

.full {
  width: 100%;
}

.filter input {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.filter span {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--dark-sea);
  text-align: right;
}

.more {
  margin: 32px auto 0 auto;
  text-align: center;
}

.back {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 24px;
}
