.ordersList {
    max-width: 80vw;
    margin: 0 auto;
    padding: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  .imgbut{
    width: 30px;
    height: 30px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .status-new {
    background-color: #f0f8ff; /* Light Blue */
  }
  
  .status-processing {
    background-color: #ffffe0; /* Light Yellow */
  }
  
  .status-completed {
    background-color: #98fb98; /* Pale Green */
  }

  .butnik{
    margin-left: 5px;
  }