.product {
  display: flex;
  align-items: center;
  max-width: calc(100% - 124px);
  padding: 24px;
  column-gap: 50px;
  color: #575757;
  align-items: flex-start;
  margin: auto;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.images {
  display: flex;
  gap: 20px;
}

.current {
  width: 40vw;
  height: 70vh;
  min-width: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  cursor: pointer;
}

.smallImages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.small {
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
}

.price {
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  margin: 10px 0 10px 0;
  color: #eeb20e;
}

.description {
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  max-width: 40vw;
  color: var(--grey);
  text-align: justify;
}

.actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
}

.manager,
.add {
  background: #ee890e;
  border-radius: 6px;
  transition: 1s;
}

.manager:hover,
.add:hover {
  background: #ecbc39;
  transition: 1s;
}

.bottom {
  margin-top: 20px;
}

.backButton {
  margin-top: 20px;
  background: none;
  color: #727272;
  padding: 5px 10px; 
  border: none;
  border-radius: 4px; 
  text-decoration: none;
  transition: color 0.3s ease; 
}

.backButton:hover {
  color: #333;  
}

/* Стили для режима редактирования */
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form input,
.form textarea,
.form select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Стили для кнопок */
.adminActions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.delete,
.edit,
.save,
.cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete {
  background-color: #dc3545; 
  color: white;
}

.edit {
  background-color: #ffc107;
  color: white;
}

.save {
  background-color: #28a745; 
  color: white;
}

.cancel {
  background-color: #6c757d;
  color: white;
}

/* Стили модального окна */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #666;
}

@media (max-width: 1024px) {
  .product {
    max-width: 90vw;
    flex-direction: column;
    align-items: center;
  }

  .current {
    height: 40vh;
    max-width: none;
  }

  .description {
    max-width: 100%;
  }

  .small {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    cursor: pointer;
  }

  .bottom {
    margin-top: 40px; 
  }

  .images {
    margin-bottom: 20px;
    gap: 5px;
    flex-direction: column;
  }

  .smallImages {
    flex-direction: row; 
    gap: 10px;
    justify-content: center;
  }
}