.products {
  padding: 20px;
  max-width: 90vw;
  margin: auto;
}

.products h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.list {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
}

.product {
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  max-width: 500px;
  max-height: 520px;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.product:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.image {
  width: 100%;
  height: 360px;
  width: 500px;
  background-size: cover;
  background-position: center;
  align-self: center;
  display: flex;
  align-items: center;
}

.wrapper {
  padding: 20px;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
  font-style: italic;
  text-align: center;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prices {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  margin-bottom: 5px;
  bottom: 0;
  text-align: center;
  margin-left: 35%;
  margin-bottom: 20px;
}

.price {
  color: #cc9b06;
  margin-left: auto;
}

.price_block {
  display: flex;
  justify-content: center;
}

.price_block_text {
  color: #333;
  margin-left: 3vw;
}

@media (min-width: 1200px) {
  .list {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
}

@media (max-width: 1200px) {
  .list {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 80vw;
    margin: auto;
  }
}

@media (max-width: 960px) {
  .list {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 90vw;
    margin: auto;
  }
}

@media (max-width: 830px) {
  .list {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 95vw;
    margin: auto;
  }
}

@media (max-width: 750px) {
  .list {
    grid-gap: 30px;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 98vw;
    margin: auto;
  }
}

@media (max-width: 710px) {
  .list {
    grid-gap: 30px;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 70vw;
    margin: auto;
  }
}

@media (max-width: 600px) {
  .list {
    grid-gap: 30px;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 80vw;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .list {
    grid-gap: 30px;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .products {
    padding: 20px;
    max-width: 95vw;
    margin: auto;
  }
}
