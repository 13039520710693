.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.wrapper {
  background: #fff;
  width: 80vw; /* 80% от ширины экрана */
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  position: fixed;
  top: 50vh; /* 50% от высоты экрана */
  left: 50vw; /* 50% от ширины экрана */
  transform: translate(-50%, -50%);
}

/* Добавил стили для центрирования модального окна по горизонтали и вертикали */
.wrapper:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em; /* Adjusts for spacing */
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  z-index: 1001;
  color: #333; /* Цвет заголовка */
  text-align: center;
  margin-bottom: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  z-index: 1001;
  row-gap: 16px;
}

.group input {
  background: #f2f2f2; /* Светло-серый цвет для полей ввода */
  border: none;
  border-radius: 8px;
  z-index: 1001;
  color: #333; /* Цвет текста */
  padding: 12px 16px;
}

.submit {
  background: #4caf50; /* Зеленый цвет для кнопки отправки */
  color: #fff; /* Белый цвет текста на кнопке */
  border: none;
  border-radius: 8px;
  z-index: 1001;
  padding: 12px;
  cursor: pointer;
}

.close {
  position: absolute;
  top: 14px;
  z-index: 1001;
  right: 14px;
  cursor: pointer;
}

.close svg {
  width: 20px;
  z-index: 1001;
  height: 20px;
  fill: #333; /* Цвет значка закрытия */
  transition: fill 0.3s ease;
}

.close:hover svg {
  fill: #fff;
  z-index: 1001;
}

.link {
  font-size: 13px;
  color: #333; /* Цвет ссылки */
  cursor: pointer;
  text-align: center;
  z-index: 1001;
  margin-top: 8px;
}

.link:hover {
  z-index: 1001;
  color: #000; /* Цвет ссылки при наведении */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.popupContent p {
  margin: 10px 0;
}

