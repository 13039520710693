.about {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
  font-family: 'Arial', sans-serif;
}

.tit {
  color: #333;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
}

.blocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opisanie {
  flex: 1;
  max-width: 50%;
  padding: 0 20px;
  color: #727272;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 600;
}

.opisanie ul {
  list-style: none;
  padding: 0;
}

.opisanie li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 46px;
}

.icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

.imaga {
  flex: 0.7;
  text-align: center;
  margin-left: 30px;
}

.carouselImage {
  width: 200px;
  height: 500px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.2s ease; 
  object-fit: cover;
}

@media (max-width: 1024px) {
  .blocks {
    flex-direction: column;
  }

  .opisanie {
    flex-shrink: 0;
    max-width: 100%;
    padding: 0 20px;
    color: #555;
    font-size: 18px;
    line-height: 1.6;
  }

  .imaga {
    margin-top: 30px;
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .blocks {
    flex-direction: column;
    align-items: flex-start;
  }

  .opisanie {
    max-width: 100%;
    font-size: 16px;
    padding: 0;
  }

  .imaga {
    max-width: 100%;
    margin-top: 20px;
    padding: 0;
  }

  .carouselImage {
    width: 200px;
    height: 200px;
  }

  .carousel .slide img {
    width: auto;
    height: 200px;
  }
}
