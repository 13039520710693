.orders {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .userData {
    width: 45%;
  }
  
  .userData h2 {
    margin-bottom: 15px;
  }
  
  .userData label {
    display: block;
    margin-bottom: 10px;
  }
  
  .userData input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    color: #666; /* Цвет текста серый */
  }
  
  .cart {
    width: 50%;
  }
  
  .cart h2 {
    margin-bottom: 15px;
  }
  
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .item {
    border: 1px solid #ddd;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .item .image {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .info {
    flex-grow: 1;
  }
  
  .price,
  .quantity,
  .total,
  .close {
    margin-top: 10px;
  }
  
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .proceed {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .proceed:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .empty {
    text-align: center;
    margin-top: 20px;
  }

  input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
    font-size: 16px;
  }
  
  /* При фокусе изменяется цвет границы */
  input:focus {
    outline: none;
    border-color: #ffa600;
    box-shadow: 0 0 5px rgba(255, 157, 0, 0.5);
  }
  
  /* Если в поле есть текст, меняем цвет границы */
  input:valid {
    border-color: #8b8b8b;
  }
  
  /* Если в поле ошибка валидации, меняем цвет границы */
  input:invalid {
    border-color: #ff9900;
  }
  
  /* Стили для placeholder */
  input::placeholder {
    color: #aaa;
  }
  
  .quantity {
    display: flex;
    align-items: center;
  }
  
  .minus,
  .plus {
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popupContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }
  
  /* Дополнительные стили для ваших окон */
  /* Первое окно (незаполненные поля) */
  .unfilledOverlay, .successOverlay{
    background: rgba(0, 0, 0, 0.5); /* Красный фон */
  }
  
  .checkboxInput {
    position: absolute;
    opacity: 0;
  }
  
  /* Стили для контейнера (внешнего вида) переключателя */
  .checkboxContainer {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  /* Стили для внешней части переключателя (фона) */
  .checkboxBackground {
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 89px;
    transition: background-color 0.3s;
  }
  
  /* Стили для внутренней части переключателя (переключателя) */
  .checkboxSwitch {
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 90px;
    transition: left 0.3s;
  }
  
  /* Стили для изменения внешнего вида переключателя при включенном состоянии */
  .checkboxInput:checked + .checkboxBackground {
    background-color: #70b412; /* Цвет фона при включенном состоянии */
  }
  
  .checkboxInput:checked + .checkboxBackground + .checkboxSwitch {
    left: 110px; /* Смещение переключателя при включенном состоянии */
  }

  .selectWrapper {
    position: relative;
    width: 100%;
  }
  .totalPrice{
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .select {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    appearance: none; /* Скрываем стандартные стрелки в селекторе */
    cursor: pointer;
    color: #666;
  }
  
  /* Стили для стрелки в селекторе */
  .selectArrow {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none; /* Делаем стрелку некликабельной */
  }
  
  /* При фокусе изменяется цвет границы */
  .select:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
  }
  
  /* Если в селекте выбрано значение, меняем цвет границы */
  .select:valid {
    border-color: #2ecc71;
  }
  
  /* Если в селекте ошибка валидации, меняем цвет границы */
  .select:invalid {
    border-color: #e74c3c;
  }
  
  /* Стили для placeholder в селекте */
  .select option[disabled]:first-child {
    color: #aaa;
  }
  
  @media (max-width: 1024px) {
    .userData {
      width: 100%; /* Занимает всю ширину на мобильных устройствах */
      order: 1; /* Изменяем порядок отображения на мобильных устройствах */
    }
  }
  
  /* Стили для блока с корзиной на мобильных устройствах */
  @media (max-width: 1024px) {
    .orders{
      margin-left: 20px;
      margin-right: 20px;
      flex-direction: column;
    }
    .cart {
      width: 100%; /* Занимает всю ширину на мобильных устройствах */
      order: 2; /* Изменяем порядок отображения на мобильных устройствах */
      margin-top: 20px; /* Добавляем отступ сверху для разделения блоков */
    }
  
    /* Дополнительные стили, если нужны */
    .cart h2 {
      margin-bottom: 15px;
    }
    
  }