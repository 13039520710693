.whatsappButton {
    z-index: 100000;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366; /* Зелёный цвет WhatsApp */
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень для объёма */
    text-decoration: none; /* Убираем подчеркивание ссылки */
    transition: background-color 0.3s ease; /* Плавный переход цвета при наведении */
  }
  
  .whatsappButton:hover {
    background-color: #128C7E; /* Более тёмный зелёный при наведении */
  }