.cat {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.categoryList {
  display: flex;
  gap: 20px; /* Уменьшаем отступ между категориями */
  flex-wrap: wrap; /* Разрешаем перенос на следующую строку */
  justify-content: center; 
}

.categoryItem {
  text-decoration: none; 
  color: rgb(160, 160, 160); /* Светло-серый цвет текста */
  font-size: 20px;
  font-weight: bold; /* Жирный шрифт */
  font-style: italic; /* Курсив */
  transition: color 0.3s; /* Плавный переход цвета */
}

.categoryItem:hover {
  color: gray; /* Темно-серый цвет при наведении */
}