.header {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  padding: 2vh 1vw;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}


.logo {
  width: 10vw;
  transition: transform 0.3s ease;
}

.logo img {
  width: 100%;
}

.logo:hover {
  transform: scale(1.05);
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.user {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.avatar:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.username {
  font-size: 16px;
  line-height: 12px;
  color: var(--grey);
  transition: color 0.3s ease;
}

.username:hover {
  color: var(--dark-sea);
}

.logout {
  width: 20px;
  margin-top: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logout img {
  width: 100%;
}

.logout:hover {
  transform: rotate(90deg);
}

.phone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phone p {
  margin: 0;
  font-size: 16px;
  transition: color 0.3s ease;
}

.account {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.account svg {
  width: 24px;
  height: 24px;
  transition: fill 0.3s ease;
}

.iconCart {
  fill: var(--grey);
}

.iconCart:hover {
  fill: rgb(244, 190, 90);
}

.cart {
  position: relative;
}

.count {
  position: absolute;
  width: 14px;
  height: 14px;
  background: #eddb1b;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 10px;
  color: var(--light);
  text-align: center;
  line-height: 14px;
}

.adminPanel,
.orders {
  display: inline-block;
  margin-left: 10px;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.adminPanel,
.orders {
  background-color: #2077b3;
}

@media (max-width: 767px) {
  .logout {
    width: 15px;
    margin-top: 5px;
  }
  .logo {
    width: 18vw;
  }
  .phone p {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .logout {
    width: 15px;
    margin-top: 5px;
  }
  .logo {
    width: 8vw;
  }
  .phone p {
    font-size: 22px;
  }
  .username {
    font-size: 22px;
    line-height: 12px;
    color: var(--grey);
  }
}