:root {
  --bg: #ffffff;
  --dark: #ffffff78;
  --dark-sea: #576067;
  --grey: #6a6a6a;
  --white: #fff;
  --violet: #cecece;
  --violet-dark: #aeaeae;
  --light: #f6f6f7;
  --sea: #616e74;
}
