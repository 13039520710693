.profile {
  flex-grow: 1;
  padding: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.group {
  width: 100%;
  background: white; /* Изменяем фон на белый */
  border-radius: 8px;
  color: #333; /* Цвет текста */
  padding: 12px 24px;
  margin-bottom: 10px; /* Добавляем небольшой отступ снизу */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Добавляем тень для утопления в основную карточку */
}

.group input,
.group textarea {
  margin-bottom: 0px;
  width: 100%;
  border: 1px; /* Убираем границы */
  background: #f1f1f1; /* Убираем фон */
  outline: none; /* Убираем обводку */
  color: #333; /* Цвет текста */
  resize: none; /* Запрещаем изменение размера текстового поля */
}

.group textarea{
  height: 300px;
  border-radius: 10px;
  font-size: 18px;
}

.group input {
  width: 100%;
}

.group input::placeholder,
.group textarea::placeholder {
  color: #999; /* Цвет плейсхолдера */
}

.border {
  margin-top: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Добавляем тень */
  position: relative; /* Делаем позиционирование относительным */
}

.logoutButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff0000; /* Change the background color as needed */
  color: #ffffff; /* Change the text color as needed */
  font-size: 16px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 37%;
}

.logoutButton:hover {
  background-color: #cc0000; /* Change the background color on hover as needed */
}

.group select {
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;
  margin-top: 10px;
  border: none;
  border-radius: 8px;
  appearance: none; /* Убираем стандартный стиль селектора */
  background: #f1f1f1;
  outline: none;
  cursor: pointer;
}

/* Стили для стрелочки в селекторе */
.group select::-ms-expand {
  display: none;
}

.group select::after {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Убираем события мыши */
}

/* Стили для опций селектора */
.group select option {
  font-size: 16px;
}

.imagePreview {
  width: 200px; /* Установите ширину и высоту в соответствии с вашими предпочтениями */
  height: 200px;
  margin-top: 10px;
  margin-left: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Выравнивание изображений по горизонтали */
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageLabel {
  margin-top: 5px;
}