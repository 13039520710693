
.bannerTitle {
  position: relative;
  text-align: center;
  background: #ffc1fe;
  box-shadow: 0 14px #ffc1fe;
  width: 160px;
  padding-top: 8px;
  padding-bottom: 5px;
  margin: auto;
  margin-bottom: 50px;
}
  
  .bannerTitle::before {
    content: "";
    position: absolute;
    bottom: -15px;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 10px solid #fff;
    left: 50%;
    transform: translateX(-50%);
  }

  .bannerTitle h2 {  
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    color: #565656;
    margin: 0; 
  }