

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 1em 1em; /* Добавлен отступ для разделения компонентов */
  font-weight: bold;
  font-style: italic;
}

.msg {
  border-top: 3px solid #ffc1fe;
  border-bottom: 3px solid #ffc1fe;
  padding: 0.75em 0 1em;
  text-align: center;
}

.banner {
  position: relative;
  text-align: center;
  background: #cad100;
  box-shadow: 0 14px #cad100;
  width: 160px;
  padding-top: 8px;
  padding-bottom: 5px;
  margin: auto;
  color: #fff;
}

.banner:before {
  content: "";
  position: absolute;
  bottom: -15px;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 10px solid #fff;
  left: 50%;
  transform: translateX(-50%);
}