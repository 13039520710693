@import url('https://fonts.googleapis.com/css?family=Open+Sans|Playfair+Display:700');

:root {
  --image-drop: 20px;
}


.header {
  position: relative;
  width: 100%;
  height: 90vh;
  max-height: 800px;
  overflow: hidden;
  text-align: center;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: overlay-fade-in 1.5s 1s ease-out forwards;
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header::after {
  content: '';
  position: absolute;
  bottom: -100px;
  left: -5%;
  right: -5%;
  height: 100px;
  background: #fff;
  z-index: 15;
  transform-origin: 0 0;
  transform: rotateZ(0);
  animation: header-rotate 0.5s 1s ease-out forwards;
}



.headerImage {
  width: 100%;
  height: calc(100% + var(--image-drop));
  object-fit: cover;
  object-position: center top;
  opacity: 0;
  transform: translateY(calc(var(--image-drop) * -1));
  animation: image-fade-in 1s 0.5s ease-out forwards;
}

@keyframes image-fade-in {
  0% {
    opacity: 0;
    transform: translateY(calc(var(--image-drop) * -1));
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
}

.visuallyHidden { 
  position: absolute; 
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.titleText {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  font-size: 180px;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: scale(0.96);
  line-height: 100px;
  animation: title-fade-in 1s 1.25s ease-out forwards;
}

@media (min-width: 750px) {
  .titleText {
    width: 50%;
    font-size: 100px;
  }
}

@keyframes title-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

main {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
}

.content {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 750px;
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  line-height: 1.5;
  color: #333;
}

@media (max-width: 768px) { 
  .header {
    height: 50vh; /* Уменьшаем высоту хедера */
    max-height: 400px;
  }

  .header::after {
    bottom: -50px; /* Поднимаем "волну" */
    height: 50px; 
  }

  .titleText {
    font-size: 90px; /* Уменьшаем размер шрифта */
    line-height: 80px; 
    padding: 0 10px; /* Уменьшаем отступы */
  }

  .content {
    font-size: 16px; /* Уменьшаем размер шрифта */
    padding: 10px;  /* Уменьшаем отступы */
  }
}

/* Стили для больших экранов (оставляем без изменений) */
.header {
  position: relative;
  width: 100%;
  height: 90vh;
  max-height: 800px;
  overflow: hidden;
  text-align: center;
}
